
import { Player, Scenario } from '@/models';
import { defineComponent } from 'vue';
import ScenarioScreen from '../Facilitator/screens/Scenario.vue';
import PlayerList from '../Facilitator/components/PlayerList.vue';

function makeid(length) {
	let result = '';
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}

export default defineComponent({
	components: {
		ScenarioScreen,
		PlayerList
	},
	setup() {
		const scenario: Scenario = {
			id: 1,
			text: `Your best friend has just been hurt badly. You're the only one who knows who did it. Right before it happens they confide in you about a disagreement they had with the person who hurt them. Your best friends' family and other friends are outraged and planning revenge.  You know something bad will happen if you reveal who did it. How do you handle the situation?`,
			category: {
				id: 1,
				title: 'Test Category',
				imageUrl:
					'https://on-the-edge-contentbucket-1l8x08167uqei.s3.amazonaws.com/which-way-is-out.png',
				description: '',
				ordinal: 1
			}
		};

		const players: Player[] = [];
		const colors = ['#FF6969', '#44AACF', '#D5F560', '#FFB341', '#B865CF'];

		for (let i = 0; i < 20; i++) {
			const nameLength = 1 + Math.floor(Math.random() * 19);
			players.push({
				id: i + 1,
				name: makeid(nameLength),
				avatarBackgroundColor:
					colors[Math.floor(Math.random() * colors.length)],
				avatarImageUrl: '/avatars/avatar-generic.svg',
				score: Math.floor(Math.random() * 10),
				handRaised: i === 0,
				questionAnswered: i === 1,
				remainingSkips: 3
			});
		}

		return { scenario, players };
	}
});
