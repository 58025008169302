
import { Scenario } from '@/models';
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		scenario: {
			type: Object as () => Scenario,
			default: null
		}
	}
});
