import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scenario_screen = _resolveComponent("scenario-screen")!
  const _component_player_list = _resolveComponent("player-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_scenario_screen, { scenario: _ctx.scenario }, null, 8, ["scenario"]),
    _createVNode(_component_player_list, {
      mode: "leaderboard",
      players: _ctx.players
    }, null, 8, ["players"])
  ], 64))
}